import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  chakra,
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import Footer from "../components/Footer";
import Framed from "../components/Framed";
import Header from "../components/Header";
import Link from "../components/Link";
import { SpecificHeaders } from "../components/SocialHeaders";
import { useHeaderImage } from "../components/images/fishMarketHeader";

const HeatherProfile = () => (
  <VStack
    alignItems="start"
    w={["full", null, "288px", "200px", null, "288px"]}
    flexGrow={{ base: "1", "2xl": 0 }}
  >
    <StaticImage
      src="../images/about/heather.jpg"
      alt="profile photo of Heather"
      width={480}
      height={480}
    />
    <Heading as="h4" fontSize="xl" pt="20px">
      Heather Roberts
    </Heading>
    <Text fontSize="md" fontFamily="heading" h={["2rem", "3.25rem"]}>
      FOUNDER &{" "}
      <chakra.br display={["none", "inline", "none", "inline", "none"]} />
      LEAD RESEARCHER
    </Text>
    <Text>
      Heather is the main researcher for Just Kai and also speaks at events and
      runs our Facebook and Twitter pages. She started Just Kai after learning
      about the terrible abuses some people suffer to produce our food: she felt
      called to be part of the solution. Outside of Just Kai she enjoys baking,
      handcrafts, cycling and swimming.
    </Text>
  </VStack>
);

const ChristineProfile = () => (
  <VStack
    alignItems="start"
    w={["full", null, "288px", "200px", null, "288px"]}
    flexGrow={{ base: "1", "2xl": 0 }}
  >
    <StaticImage
      width={480}
      height={480}
      src="../images/about/christine.jpg"
      alt="profile photo of Christine"
    />
    <Heading as="h4" fontSize="xl" pt="20px">
      Christine Shue
    </Heading>
    <Text fontSize="md" fontFamily="heading" h={["2rem", "3.25rem"]}>
      WRITER &{" "}
      <chakra.br display={["none", "inline", "none", "inline", "none"]} />
      INFORMATION DESIGNER
    </Text>
    <Text>
      Christine works with Heather to turn the detailed research notes into
      quick summary guides. She started helping with Just Kai to learn about
      ethical business operations and how companies can address human rights
      risks. Outside of Just Kai (and her day job as an analyst), Christine
      enjoys reading, playing guitar, occasionally cooking, and hanging with
      family and friends.
    </Text>
  </VStack>
);

const BarbaraProfile = () => (
  <VStack
    alignItems="start"
    w={["full", null, "288px", "200px", null, "288px"]}
    flexGrow={{ base: "1", "2xl": 0 }}
  >
    <StaticImage
      src="../images/about/barbara.jpg"
      alt="profile photo of Barbara"
      width={480}
      height={480}
    />
    <Heading as="h4" fontSize="xl" pt="20px">
      Barbara Bulkeley
    </Heading>
    <Text fontSize="md" fontFamily="heading" h={["2rem", "3.25rem"]}>
      RESEARCH ASSISTANT
    </Text>
    <Text>
      Barbara has begun to help Heather with the research side of things,
      checking company information and websites. She spent several years in
      central Africa, has visited many countries in Asia and having seen the
      conditions in which so many people live, believes that our food should not
      come to us at the expense of others’ freedom. In her spare time she enjoys
      singing, reading, being with grandchildren and walking.
    </Text>
  </VStack>
);

const MartinProfile = () => (
  <VStack
    alignItems="start"
    w={["full", null, "288px", "200px", null, "288px"]}
    flexGrow={{ base: "1", "2xl": 0 }}
  >
    <StaticImage
      src="../images/about/martin.jpg"
      alt="profile photo of Heather"
      width={480}
      height={480}
    />
    <Heading as="h4" fontSize="xl" pt="20px">
      Martin Roberts
    </Heading>
    <Text fontSize="md" fontFamily="heading" h={["2rem", "3.25rem"]}>
      WEBMASTER
    </Text>
    <Text>
      Martin maintains the Just Kai website as well as providing moral support
      to Heather, his wife. He grew up in S.E. Asia and sees Just Kai as part of
      helping the kids he grew up around have better opportunities in life. He
      works as a software engineer, is heavily involved in his local church and
      loves riding his bike for transport.
    </Text>
  </VStack>
);

const GraceProfile = () => (
  <VStack
    alignItems="start"
    w={["full", null, "288px", "200px", null, "288px"]}
    flexGrow={{ base: "1", "2xl": 0 }}
  >
    <StaticImage
      src="../images/about/grace.jpg"
      alt="profile photo of Grace"
      width={480}
      height={480}
    />
    <Heading as="h4" fontSize="xl" pt="20px">
      Grace Ntreh
    </Heading>
    <Text fontSize="md" fontFamily="heading" h={["2rem", "3.25rem"]}>
      SOCIAL MEDIA CREATOR
    </Text>
    <Text>
      Grace has been passionate about slavery ever since childhood when she
      first discovered the work of William Wilberforce and the Clapham Sect.
      Grace discovered Just Kai whilst she was volunteering at Tearfund's
      Justice Conference in 2022. Eager to get involved, she contacted Heather,
      and within a couple of months she’d joined the team. Outside of Just Kai,
      you'll find Grace tiki touring around the world, visiting family and
      friends.
    </Text>
  </VStack>
);

export default function AboutPage() {
  return (
    <>
      <Header imageHook={useHeaderImage}>
        <SpecificHeaders title="About Us" path="/contact/" />
        <Heading as="p" fontSize="md">
          ABOUT US
        </Heading>
        <Heading as="p" py={3}>
          <Link
            target="_blank"
            href="/blog/2019-11-22-key-statistics/#around-7-million-slaves-are-making-physical-goods-for-sale"
          >
            Millions of people
          </Link>{" "}
          are enslaved producing goods for sale.
        </Heading>
        <Text fontSize="2xl" lineHeight={1.4} mt="1rem">
          Food made with slave labour is hidden in plain sight in every
          supermarket in New Zealand. Just Kai wants to see that change!
        </Text>
      </Header>

      <Box as="main">
        <Framed id="blurb">
          <Text
            as="div"
            mx={[0, null, null, "200px", null, "276px"]}
            textStyle="simple"
            pt={["1rem", null, "55px"]}
            pb={["3rem", null, "110px"]}
          >
            <h5>
              At Just Kai we research supply chains to find and promote slave
              free choices.
            </h5>
            <p>
              We're a group of Kiwis trying to encourage other Kiwis to buy food
              they're confident wasn't produced by slaves. Why? If no one buys
              slave-produced goods, then that's one less reason to enslave
              people in the first place. It's one way we can all help to end
              Modern Slavery.
            </p>
            <p>
              Our focus is the three foods where slavery is most common, which
              are fish, cocoa and sugar. We produce{" "}
              <Link href="/guides/">buying guides</Link> on food with a high
              risk of slavery in its supply chain, particularly fish,
              cocoa/chocolate and sugar. Subscribe to our{" "}
              <Link href="/blog/">blog</Link> or{" "}
              <Link target="_blank" href="https://www.facebook.com/justkainz">
                Facebook page
              </Link>{" "}
              for updates and to receive seasonal guides such as our annual
              Easter chocolate guide.
            </p>
            <p>
              Our founder, Heather, is available to speak at churches, community
              organisations and events, particularly in the Auckland region.
            </p>
          </Text>
        </Framed>

        <Box id="team" bg="brand.midBlue" color="brand.light">
          <Framed pt={["2rem", null, "60px"]} pb={["3rem", null, "110px"]}>
            <Heading>Our Team</Heading>
            <Flex
              columnGap={{
                base: "1rem",
                md: "4rem",
                lg: "1rem",
                xl: "4rem",
                "2xl": "1rem",
              }}
              rowGap="3rem"
              pt={["1.5rem", null, "90px"]}
              pb={["4rem", null, "90px"]}
              justifyContent={{ base: "space-around", "2xl": "space-between" }}
              wrap="wrap"
            >
              <HeatherProfile />
              <ChristineProfile />
              <GraceProfile />
              <BarbaraProfile />
              <MartinProfile />
            </Flex>
            <Center>
              <Box w={["80%", null, "60%"]}>
                <Divider mb="20px" />
                <Text align="center">
                  The work of Just Kai is also made possible by Anna Seccombe,
                  Sarah Woodfield, Heather Woods, and Grant Taylor who help out
                  at events and check labeling of products in the wild.
                </Text>
              </Box>
            </Center>
          </Framed>
        </Box>

        <Framed id="media">
          <Grid
            templateColumns={[
              "1fr",
              null,
              "200px fit-content(65ch)",
              null,
              null,
              "276px fit-content(65ch)",
            ]}
            rowGap={["1rem", null, "60px"]}
            pt={["2rem", null, "90px"]}
            pb={["3rem", null, null, "140px"]}
          >
            <GridItem>
              <Heading fontFamily="body" color="brand.lightBlue">
                Annual <chakra.br display={["none", null, "inline"]} />
                Reports
              </Heading>
            </GridItem>
            <GridItem>
              <Text textStyle="boldList">
                <UnorderedList>
                  <ListItem>
                    <Link href="/blog/2023-02-03-just-kai-annual-report-2022/">
                      2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="/blog/2022-02-01-just-kai-annual-report-2021/">
                      2021
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="/blog/2021-02-26-just-kai-annual-report-2020/">
                      2020
                    </Link>
                  </ListItem>
                </UnorderedList>
              </Text>
            </GridItem>
            <GridItem pt={["2rem", null, 0]} colStart="1">
              <Heading fontFamily="body" color="brand.lightBlue">
                Media
              </Heading>
            </GridItem>
            <GridItem>
              <Text textStyle="simple">
                {/* No <p> tag here to avoid top margin */}
                Since starting Just Kai we’ve had the opportunity to share at a
                bunch of events, including{" "}
                <Link
                  target="_blank"
                  href="https://www.thejusticeconference.org.nz/"
                >
                  The Justice Conference
                </Link>
                ,{" "}
                <Link
                  target="_blank"
                  href="https://justkai.org.nz/blog/2019-03-27-fairfield-conf/"
                >
                  Fairfield conference
                </Link>
                ,{" "}
                <Link
                  target="_blank"
                  href="https://www.facebook.com/devonportethicalmarket/"
                >
                  Devonport Ethical Christmas Market
                </Link>
                , and church services.
                <h5>Articles</h5>
                <UnorderedList>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://www.stuff.co.nz/business/industries/130717816/the-nasty-surprise-hidden-in-your-fish-this-christmas"
                    >
                      quoted in Stuff: "The nasty surprise hidden in your fish
                      this Christmas" | Dec 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://goodmagazine.co.nz/where-does-your-tea-come-from/"
                    >
                      Good Magazine: "Where does your tea come from?" | May 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://www.stuff.co.nz/business/industries/128341740/the-worst-ingredient-in-chocolate-easter-eggs-is-illegal-for-children"
                    >
                      quoted in Stuff: The worst 'ingredient' in chocolate
                      Easter eggs is illegal for children | April 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://tearfundnz.exposure.co/spilling-the-beans-the-bitter-truth-of-chocolate"
                    >
                      collaboration with Tearfund: Spilling the beans - the
                      bitter truth of chocolate | April 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://fairandgood.co.nz/journal/just-kai-guide-ethical-chocolate-guide"
                    >
                      fair&amp;good guest post on ethical chocolate | December
                      2021
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://fairandgood.co.nz/journal/just-kai-can-big-companies-be-ethical"
                    >
                      fair&amp;good guest post on can big companies be ethical |
                      October 2021
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://fairandgood.co.nz/journal/just-kai-guide-to-ethical-food-certfications"
                    >
                      fair&amp;good guest post on ethical food certifications |
                      September 2021
                    </Link>
                  </ListItem>

                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://fairandgood.co.nz/journal/the-just-kai-guide-to-ethical-seafood"
                    >
                      fair&amp;good guest post on ethical seafood | June 2021
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://fairandgood.co.nz/journal/can-we-shop-ethically-in-the-supermarket"
                    >
                      fair&amp;good guest post on ethical supermarket shopping |
                      March 2021
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://justkai.org.nz/blog/2020-04-14-which-eggs-are-slavery-free-this-easter/"
                    >
                      Tearfund mailout on Easter Eggs | Apr 2020
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://baptistmag.org.nz/meet-heather-roberts-just-kai/"
                    >
                      Baptist Mag interview with Heather | Feb 2020
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://justkai.org.nz/blog/2019-12-08-what-you-need-to-know-about-your-christmas-meal/"
                    >
                      Tearfund mailout on Christmas treats | Dec 2019
                    </Link>
                  </ListItem>
                </UnorderedList>
                <h5>Talks</h5>
                <UnorderedList>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://www.youtube.com/watch?v=-UcafnmDT6I"
                    >
                      Panel discussion of Worker Exploitation at the Justice
                      Conference | September 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://drive.google.com/file/d/1K9fG2jjIfd5kEebXyOhl0a-iFFQA90wY/view?usp=sharing"
                    >
                      Interviewed on Radio Rhema | August 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://drive.google.com/file/d/1aoB4Qkq9xVDbpnR-6WOudH4JfMAA7FG2/view?usp=sharing"
                    >
                      Interviewed on Radio New Zealand News | April 2022
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://justkai.org.nz/blog/2021-02-27-just-kai-at-hosanna-avondale-baptist/"
                    >
                      Hosanna Avondale Baptist | November 2020
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://justkai.org.nz/blog/2020-08-21-just-kai-at-4cs/"
                    >
                      4Cs (a senior citizen support organisation) | August 2020
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link target="_blank" href="https://youtu.be/fG945MHyEiw">
                      Ponsonby Baptist Church | July 2019
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://justkai.org.nz/blog/2019-05-31-heather-speaking-at-the-fairfield-conference/"
                    >
                      Fairfield Conference | March 2019
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      target="_blank"
                      href="https://drive.google.com/file/d/1_UQeyBA_vFZO8IG1fEt0kfP0x8PSeWyW/view"
                    >
                      Radio Rhema Interview | Nov 2018
                    </Link>
                  </ListItem>
                </UnorderedList>
              </Text>
            </GridItem>
          </Grid>
        </Framed>
      </Box>

      <Footer />
    </>
  );
}
